<script>
  import Checkbox from "@smui/checkbox";
  import FormField from "@smui/form-field";
  import IconButton from "@smui/icon-button";
  import Switch from "@smui/switch";
  import Tooltip, { Wrapper } from "@smui/tooltip";
  import { HTTPError } from "ky";
  import { getContext, onDestroy, onMount } from "svelte";
  import { fade } from "svelte/transition";
  import { _ } from "svelte-i18n";

  import ConfirmDialog from "~/components/ConfirmDialog.svelte";
  import Footer from "~/components/Footer.svelte";
  import Header from "~/components/Header.svelte";
  import QrCodeScanner from "~/components/QrCodeScanner.svelte";
  import RoleIcon from "~/components/RoleIcon.svelte";
  import additionalDataForDeliverylist from "~/libs/additionalDataForDeliverylist";
  import addressUtils from "~/libs/addressUtils";
  import { beep, destroyAudioContext } from "~/libs/audio";
  import backendApi, { OfflineException } from "~/libs/backendApi";
  import { HandledError } from "~/libs/commonTypes";
  import {
    ABSENCE,
    ACCEPT_DENIED,
    CONTEXT_KEY_USER,
    ConfirmDialogTypes,
    NotificationCategory,
    PAYMENT_NOT_POSSIBLE,
    QrHomeTypes,
    QrScanModes,
    ReturnReason,
    STATUS_DELIVERED,
    STATUS_HELD_IN_DEPOT,
    STATUS_OUT_FOR_DELIVERY,
    STATUS_REQUESTING_FOR_RETURN,
    STATUS_WAITING_FOR_RETURN,
    TROUBLE_ADDRESS_UNKNOWN,
    TROUBLE_LOST_OR_DAMAGED,
    TROUBLE_NON_ADDRESS,
    UNABLE_TO_DELIVER_TODAY,
  } from "~/libs/constants";
  import loadingProgress from "~/libs/loadingProgress";
  import logger from "~/libs/logger";
  import notificationHistoryUtils from "~/libs/notificationHistoryUtils";
  import pageRouter from "~/libs/pageRouter";
  import { CodeType } from "~/libs/qrCodeScanner";
  import reminder from "~/libs/reminder";
  import { updateCenter } from "~/libs/stores";
  import { syncOperationState } from "~/libs/syncOperationState";
  import { toast } from "~/libs/toast";
  import {
    formatTrackingNumber,
    parseCodabarEmbeddedTrackingNumber,
    parseQRCodeEmbeddedTrackingNumber,
  } from "~/libs/trackingNumberUtils";

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  const centerId = $updateCenter ? $updateCenter.split("/")[0] : null;
  const centerName = $updateCenter ? $updateCenter.split("/")[1] : null;

  /** @type {Set<string>} */
  const decodedTextList = new Set();

  /** @type {QrCodeScanner} */
  let qrCodeScanner;

  /** 確認ダイアログ @type {ConfirmDialog} */
  let confirmDialog;

  /** 各荷物の確認事項（複数個口）が全て確認済みかどうか @type {boolean}*/
  let allChecked = true;

  /**
   * @typedef {import("~/libs/commonTypes").DeliveryPackage & {
   *   address: string,
   *   scanned: boolean,
   *   isManuallyInputted: boolean,
   *   returnTarget: boolean,
   *   displayExtraEventType: number,
   *   requestingForReturn: boolean,
   *   reportedForAdmin: boolean,
   *   multipleBoxesChecked: boolean,
   * }} TakebackPackageFromDeliveryList
   *
   * @typedef {{
   *   trackingNumber: string,
   *   numberOfPackages: number,
   *   address?: string,
   *   correctedAddress?: string,
   *   receiverName?: string,
   *   unacquired: boolean,
   *   isManuallyInputted: boolean,
   *   returnTarget: boolean,
   *   registerdExtraEventType?: number,
   *   displayExtraEventType?: number,
   *   requestingForReturn: boolean,
   *   returnReason?: number,
   *   numberOfDeliveryAttempts?: number,
   *   reportedForAdmin: boolean,
   *   multipleBoxesChecked: boolean,
   * }} TakebackPackage
   */

  /** 持ち戻り対象 */
  const takebackPackages = {
    /** @type {Array<TakebackPackageFromDeliveryList>} 配達リストからの持ち戻り対象 */
    fromDeliveryList: (userContext.deliveryList ?? [])
      .filter((e) => e.statusText === "不")
      .map((e) => {
        const takeback = /** @type {TakebackPackageFromDeliveryList} */ (e);
        takeback.address = takeback.receiverAddress1;
        if (takeback.receiverAddress2) {
          takeback.address += " " + takeback.receiverAddress2;
        }
        takeback.address = addressUtils.trimPrefecture(takeback.address);
        takeback.scanned = false;
        // 持ち戻り理由(最後のextraEvent)を設定（ない場合はnull）
        takeback.displayExtraEventType = getLastExtraEventType(
          takeback.extraEvent,
        );
        takeback.requestingForReturn = Number.isInteger(takeback.returnStatus);
        takeback.returnTarget =
          Number.isInteger(takeback.returnStatus) ||
          isReturnTarget(takeback.displayExtraEventType);
        // 損壊による返品の場合、配達管理者への報告済みフラグをfalse(未報告)で初期設定
        // ※同じ区分値になる「紛失」のケースは考慮不要（紛失登録した荷物はリストに残っていないため）
        takeback.reportedForAdmin =
          takeback.displayExtraEventType != TROUBLE_LOST_OR_DAMAGED ||
          takeback.requestingForReturn;
        if (!takeback.reportedForAdmin) {
          allChecked = false;
        }
        // 複数個口の場合
        takeback.multipleBoxesChecked =
          takeback.numberOfPackages > 1 ? false : true;
        if (takeback.numberOfPackages > 1) {
          allChecked = false;
        }

        return takeback;
      }),

    /** @type {Array<TakebackPackage>} 新規にスキャンした持ち戻り対象 */
    newlyScanned: [],
  };

  /**
   * @param {Array<{ extraEventType: number }>} extraEvent
   * @returns {number}
   */
  function getLastExtraEventType(extraEvent) {
    if (!extraEvent || extraEvent.length === 0) {
      return null;
    }
    return extraEvent[extraEvent.length - 1].extraEventType;
  }

  /** 確定ボタンの活性／非活性 */
  let btnDisabled = true;
  $: btnDisabled =
    numberOfTakebackPackages.numberOfDelivery +
      numberOfReturnPackages.numberOfDelivery ===
    0;

  let registering = false;

  /**
   * 再配達対象の配達件数と荷物の個数
   * @type {{numberOfDelivery: number, numberOfPackages: number}}
   */
  let numberOfTakebackPackages = { numberOfDelivery: 0, numberOfPackages: 0 };

  /**
   * 返品対象の配達件数と荷物の個数
   * @type {{numberOfDelivery: number, numberOfPackages: number}}
   */
  let numberOfReturnPackages = { numberOfDelivery: 0, numberOfPackages: 0 };

  /** 戻る確認ダイアログ @type {ConfirmDialog} */
  let backConfirmDialog;

  /** データ破棄確認後に実行する処理 @type {() => void} */
  let functionAfterDiscard;

  onMount(
    loadingProgress.wrapAsync(async () => {
      await qrCodeScanner.startScanning();
    }),
  );

  onDestroy(() => {
    // AudioContextを破棄（iOSはAudioContextを破棄しないと次回起動時に音が鳴らなくなる）
    destroyAudioContext();
  });

  /**
   * 配達管理者へ報告済みか否かを確認
   * @returns {boolean} 配達管理者へ報告済みか否か
   */
  function checkReportedForAdmin() {
    for (let i = 0; i < takebackPackages.fromDeliveryList.length; i++) {
      if (
        takebackPackages.fromDeliveryList[i].scanned &&
        !takebackPackages.fromDeliveryList[i].reportedForAdmin
      ) {
        return false;
      }
    }

    for (let i = 0; i < takebackPackages.newlyScanned.length; i++) {
      if (!takebackPackages.newlyScanned[i].reportedForAdmin) {
        return false;
      }
    }

    return true;
  }

  async function updateStatuses() {
    registering = true;
    await loadingProgress.wrapAsync(applyUpdateToShippingList)();
  }

  async function applyUpdateToShippingList() {
    try {
      let successList = [];
      let hasSuccess = false;
      if (
        numberOfTakebackPackages.numberOfDelivery ||
        numberOfReturnPackages.numberOfDelivery
      ) {
        // ステータス更新API実行
        const data = await execStatusUpdateApi();
        decodedTextList.clear();

        if (data.success) {
          successList = data.success;
          hasSuccess = true;
        }
        if (data.updateFailed) {
          const updateFailedNumbers = data.updateFailed;
          const outputTrackingNumbers = updateFailedNumbers
            .map((t) => formatTrackingNumber(t))
            .join(", ");
          toast.error(
            $_("errors.isCannotBeTakebackPackage_4", {
              values: { trackingNumber: outputTrackingNumbers },
            }),
          );
          notificationHistoryUtils.deleteAndAddHistory(
            userContext.loginUser.username,
            NotificationCategory.ERROR,
            $_("errors.isCannotBeTakebackPackage_4", {
              values: { trackingNumber: outputTrackingNumbers },
            }),
          );
          for (let i = 0; i < updateFailedNumbers.length; i++) {
            successList.push({ trackingNumber: updateFailedNumbers[i] });
          }
        }
      }

      // ローカルストレージからリスト取得
      let shippingList = userContext.deliveryList ?? [];
      if (successList.length >= 1) {
        /* APIの実行が1件以上成功した場合 */
        let newShippingList = [];
        for (let i = 0; i < shippingList.length; i++) {
          newShippingList.push(shippingList[i]);
          for (let j = 0; j < successList.length; j++) {
            if (
              shippingList[i].trackingNumber == successList[j].trackingNumber
            ) {
              // 配達リストから削除する荷物の付加データも削除
              additionalDataForDeliverylist.deleteByTrackingNumber(
                shippingList[i].trackingNumber,
              );
              newShippingList.pop();
              break;
            }
          }
        }
        shippingList = newShippingList;
        if (hasSuccess) toast.info($_("message.updateCompleteHeldInDepot"));
        if (shippingList.length == 0) {
          // 未配達と持ち戻り対象が0になった場合、リマインドOFF
          userContext.undelivered = false;
          reminder.remindOff();
        }
        userContext.deliveryList = shippingList;
        userContext.store();

        // 荷物の持ち戻り・返品処理後、その場で業務を終了しても問題無いように稼働状況の同期を即座に行う
        syncOperationState(userContext);
      }
      // API実行の成否に関わらず配達リスト画面へ遷移
      pageRouter.moveToList();
    } catch (error) {
      if (
        error instanceof HTTPError &&
        error.response &&
        error.response.status == 401
      ) {
        toast.error($_("errors.unauthorized"));
        logout();
      } else if (
        error instanceof HTTPError &&
        error.response &&
        error.response.status == 403
      ) {
        toast.error($_("errors.forbidden"));
        logout();
      } else {
        // オフライン状態やサーバーエラー応答等が発生した場合
        logger.error(
          "[TakebackPackageToDepotWithQrCodeScan] 持戻り・返品集荷待ち登録でエラーが発生しました",
          {
            username: userContext.loginUser?.username,
            locationId: centerId,
            takebackPackages: {
              fromDeliveryList: takebackPackages.fromDeliveryList.map((e) => ({
                trackingNumber: e.trackingNumber,
                numberOfPackages: e.numberOfPackages,
                scanned: e.scanned,
                isManuallyInputted: e.isManuallyInputted,
                returnTarget: e.returnTarget,
                displayExtraEventType: e.displayExtraEventType,
                requestingForReturn: e.requestingForReturn,
                reportedForAdmin: e.reportedForAdmin,
                multipleBoxesChecked: e.multipleBoxesChecked,
              })),
              newlyScanned: takebackPackages.newlyScanned.map((e) => ({
                trackingNumber: e.trackingNumber,
                numberOfPackages: e.numberOfPackages,
                isManuallyInputted: e.isManuallyInputted,
                returnTarget: e.returnTarget,
                registerdExtraEventType: e.registerdExtraEventType,
                displayExtraEventType: e.displayExtraEventType,
                requestingForReturn: e.requestingForReturn,
                returnReason: e.returnReason,
                numberOfDeliveryAttempts: e.numberOfDeliveryAttempts,
                reportedForAdmin: e.reportedForAdmin,
                multipleBoxesChecked: e.multipleBoxesChecked,
              })),
            },
          },
          error,
        );
        // 閉塞フラグを確認し、オフラインモード切替えが可能かを判定
        if (import.meta.env.VITE_DISABLED_OFFLINE_MODE_DELIVERED !== "true") {
          // オフラインモード切替えが可能な場合、オフラインモード切替えヘルプを表示
          if (error instanceof OfflineException) {
            toast.recommendOfflineMode($_("errors.offline"));
          } else {
            toast.recommendOfflineMode($_("errors.defaultMessage"));
          }
        } else {
          // オフラインモード切替えが不可の場合、エラーメッセージのみ表示
          if (error instanceof OfflineException) {
            toast.error($_("errors.offline"));
          } else {
            toast.error($_("errors.defaultMessage"));
          }
        }
      }
    } finally {
      registering = false;
    }
  }

  async function execStatusUpdateApi() {
    let body = new FormData();
    let statusUpdateEvent = { response: true };
    let events = [];

    // 配達リストから持ち戻り対象を取得
    for (let i = 0; i < takebackPackages.fromDeliveryList.length; i++) {
      if (takebackPackages.fromDeliveryList[i]?.scanned) {
        let event = {
          trackingNumber: takebackPackages.fromDeliveryList[i].trackingNumber,
          status: STATUS_HELD_IN_DEPOT,
        };
        if (takebackPackages.fromDeliveryList[i].returnTarget) {
          // スキャン済みかつ返品対象の荷物の場合、返品待ちのステータスと返品理由を設定
          event.returnStatus = STATUS_WAITING_FOR_RETURN;
          event.returnReason = getReturnReasonToRegister(
            takebackPackages.fromDeliveryList[i].returnReason,
            takebackPackages.fromDeliveryList[i].displayExtraEventType,
          );
        }
        if (centerId) {
          event.locationId = new Number(centerId);
        }
        if (takebackPackages.fromDeliveryList[i].isManuallyInputted) {
          event.isManuallyInputted =
            takebackPackages.fromDeliveryList[i].isManuallyInputted;
        }
        events.push(event);
      }
    }

    // 新規スキャンリストから持ち戻り対象を取得
    for (let i = 0; i < takebackPackages.newlyScanned.length; i++) {
      let event = {
        trackingNumber: takebackPackages.newlyScanned[i].trackingNumber,
        status: STATUS_HELD_IN_DEPOT,
        extraEventType: UNABLE_TO_DELIVER_TODAY,
      };
      if (takebackPackages.newlyScanned[i].returnTarget) {
        // 返品対象の荷物の場合、返品待ちのステータスを設定
        event.returnStatus = STATUS_WAITING_FOR_RETURN;
        event.returnReason = getReturnReasonToRegister(
          takebackPackages.newlyScanned[i].returnReason,
          takebackPackages.newlyScanned[i].displayExtraEventType,
        );
      }
      if (centerId) {
        event.locationId = new Number(centerId);
      }
      if (takebackPackages.newlyScanned[i].isManuallyInputted) {
        event.isManuallyInputted =
          takebackPackages.newlyScanned[i].isManuallyInputted;
      }
      events.push(event);
    }

    statusUpdateEvent["events"] = events;
    let blob2 = new Blob([JSON.stringify(statusUpdateEvent)], {
      type: "application/json",
    });
    body.append("status-update-event", blob2);

    return backendApi.updateShipmentStatus(body);
  }

  /**
   * @param {string} decodedText
   * @param {boolean} needsDecode
   * @param {import("~/libs/qrCodeScanner").CodeType} codeType
   */
  async function onScanSuccess(decodedText, needsDecode, codeType) {
    let isManuallyInputted = false;
    const currentDecodedTextListSize = decodedTextList.size;

    /** @type {Error} */
    let parseError;
    try {
      if (needsDecode) {
        decodedText =
          codeType === CodeType.QRCODE
            ? parseQRCodeEmbeddedTrackingNumber(decodedText)
            : parseCodabarEmbeddedTrackingNumber(decodedText);
      } else {
        isManuallyInputted = true;
      }
    } catch (error) {
      parseError = error;
    } finally {
      decodedTextList.add(decodedText);
    }

    if (decodedTextList.size === currentDecodedTextListSize) {
      // スキャン済みのデータは無視
      return;
    } else if (parseError) {
      toast.error(parseError.message);
      return;
    }

    try {
      let i;
      for (i = 0; i < takebackPackages.fromDeliveryList.length; i++) {
        if (
          takebackPackages.fromDeliveryList[i].trackingNumber == decodedText
        ) {
          // 持ち戻り対象リストに存在する荷物の場合、スキャン済みに更新
          takebackPackages.fromDeliveryList[i].scanned = true;
          takebackPackages.fromDeliveryList[i].isManuallyInputted =
            isManuallyInputted;

          // 荷物数カウントを更新
          numberOfTakebackPackages = countTakebackPackages();
          numberOfReturnPackages = countReturnPackages();
          break;
        }
      }
      if (i == takebackPackages.fromDeliveryList.length) {
        // 持ち戻り対象リストに存在しない場合、新規スキャンリストに追加
        takebackPackages.newlyScanned.push({
          trackingNumber: decodedText,
          numberOfPackages: 1,
          unacquired: true,
          isManuallyInputted: isManuallyInputted,
          requestingForReturn: false,
          returnTarget: false,
          reportedForAdmin: true,
          multipleBoxesChecked: true,
        });

        await backendApi
          .getShipmentInfoByQrScan(
            decodedText,
            QrScanModes.TAKEBACK_PACKAGE_TO_DEPOT,
          )
          .then((qrScanResponse) => {
            const index = takebackPackages.newlyScanned.findIndex(
              (e) => e.trackingNumber === decodedText,
            );
            if (index != -1) {
              if (
                !qrScanResponse.lost &&
                (qrScanResponse.returnStatus === STATUS_REQUESTING_FOR_RETURN ||
                  (!Number.isInteger(qrScanResponse.returnStatus) &&
                    qrScanResponse.status === STATUS_OUT_FOR_DELIVERY))
              ) {
                /* 紛失登録済みでなく返品要求中の場合、もしくは紛失登録済みでなく返品未登録かつ配送ステータスが持ち出し中の場合 */
                // 住所を設定
                takebackPackages.newlyScanned[index].address =
                  addressUtils.trimPrefecture(qrScanResponse.address);
                // 訂正後住所を設定
                takebackPackages.newlyScanned[index].correctedAddress =
                  qrScanResponse.correctedReceiverAddress;
                // 受取人名を設定
                takebackPackages.newlyScanned[index].receiverName =
                  qrScanResponse.receiverName;
                // 配達試行回数を設定
                takebackPackages.newlyScanned[index].numberOfDeliveryAttempts =
                  qrScanResponse.numberOfDeliveryAttempts;
                // データの未取得を設定
                takebackPackages.newlyScanned[index].unacquired = false;
                // 持ち戻り理由(最後のextraEvent)を設定
                takebackPackages.newlyScanned[index].registerdExtraEventType =
                  UNABLE_TO_DELIVER_TODAY;
                // 画面表示用の持ち戻り理由を設定
                takebackPackages.newlyScanned[index].displayExtraEventType =
                  UNABLE_TO_DELIVER_TODAY;
                // 返品要求中か否かを設定
                takebackPackages.newlyScanned[index].requestingForReturn =
                  qrScanResponse.returnStatus === STATUS_REQUESTING_FOR_RETURN;
                // 返品理由を設定
                takebackPackages.newlyScanned[index].returnReason =
                  qrScanResponse.returnReason;
                // 返品対象か否かを設定
                takebackPackages.newlyScanned[index].returnTarget =
                  qrScanResponse.returnStatus ===
                    STATUS_REQUESTING_FOR_RETURN ||
                  isReturnTarget(
                    takebackPackages.newlyScanned[index].displayExtraEventType,
                  );
                // 損壊登録ありの場合、配達管理者への報告済みフラグをfalse(未報告)で初期設定
                takebackPackages.newlyScanned[index].reportedForAdmin =
                  takebackPackages.newlyScanned[index].displayExtraEventType !=
                    TROUBLE_LOST_OR_DAMAGED ||
                  takebackPackages.requestingForReturn;
                takebackPackages.newlyScanned[index].numberOfPackages =
                  qrScanResponse.numberOfPackages;
                if (!takebackPackages.newlyScanned[index].reportedForAdmin) {
                  allChecked = false;
                }
                if (qrScanResponse.numberOfPackages > 1) {
                  // 複数個口の場合
                  takebackPackages.newlyScanned[index].multipleBoxesChecked =
                    false;
                  allChecked = false;
                }
                takebackPackages.newlyScanned = takebackPackages.newlyScanned;
              } else {
                /* 紛失登録済み、もしくは返品ステータスが返品要求中以外、もしくは返品未登録で配送ステータスが持ち出し中以外の場合 */
                takebackPackages.newlyScanned.splice(index, 1);
                takebackPackages.newlyScanned = takebackPackages.newlyScanned;

                // エラーメッセージを表示
                let errorMessageId;
                if (qrScanResponse.lost) {
                  errorMessageId = "errors.isCannotBeTakebackPackage_5";
                } else if (Number.isInteger(qrScanResponse.returnStatus)) {
                  errorMessageId = "errors.isCannotBeTakebackPackage_6";
                } else if (qrScanResponse.status === STATUS_HELD_IN_DEPOT) {
                  errorMessageId = "errors.isCannotBeTakebackPackage_1";
                } else if (qrScanResponse.status === STATUS_DELIVERED) {
                  errorMessageId = "errors.isCannotBeTakebackPackage_2";
                } else {
                  errorMessageId = "errors.isCannotBeTakebackPackage_3";
                }
                toast.error(
                  $_(errorMessageId, {
                    values: {
                      trackingNumber: formatTrackingNumber(decodedText),
                    },
                  }),
                );
              }
            }
            // 荷物数カウントを更新
            numberOfTakebackPackages = countTakebackPackages();
            numberOfReturnPackages = countReturnPackages();
          })
          .catch((error) => {
            logger.error(
              "[TakebackPackageToDepotWithQrCodeScan] QRコードスキャンAPIの呼出・データ統合処理でエラーが発生しました",
              {
                username: userContext.loginUser?.username,
                locationId: centerId,
                trackingNumber: decodedText,
              },
              error,
            );
            takebackPackages.pop();
            toast.error(
              $_("errors.getPackageInfoFailed", {
                values: { trackingNumber: formatTrackingNumber(decodedText) },
              }),
              { popsWhenPageMoved: true },
            );
          });
      }

      beep();
    } catch (error) {
      if (error instanceof HandledError) {
        toast.error(error.message);
      } else {
        toast.error($_("errors.takebackListFailed"));
        console.log(error);
      }
    }
  }

  function onScanError(errorMessage, error) {
    console.log(
      "[TakebackPackageToDepotWithQrCodeScan.svelte] onScanError:",
      errorMessage,
      error,
    );
  }

  function logout() {
    pageRouter.moveToLogin();
  }

  /**
   * 返品対象か否かを判定し、返品対象の場合にtrueを返す
   * @param {number} extraEventType
   * @returns {boolean}
   */
  function isReturnTarget(extraEventType) {
    switch (extraEventType) {
      case TROUBLE_NON_ADDRESS:
        return true;
      case TROUBLE_ADDRESS_UNKNOWN:
        return true;
      case TROUBLE_LOST_OR_DAMAGED:
        return true;
      case UNABLE_TO_DELIVER_TODAY:
        return false;
      case ABSENCE:
        return false;
      case ACCEPT_DENIED:
        return true;
      case PAYMENT_NOT_POSSIBLE:
        return false;
      default:
        return false;
    }
  }

  /**
   * 返品／持ち戻りの切替えが可能か否かを判定し、切替え不可の場合にtrueを返す
   * @param {boolean} requestingForReturn
   * @param {number} extraEventType
   * @returns {boolean}
   */
  function isCannotSwitchReturnTarget(requestingForReturn, extraEventType) {
    if (requestingForReturn) {
      return true;
    }
    switch (extraEventType) {
      case TROUBLE_NON_ADDRESS:
        return false;
      case TROUBLE_ADDRESS_UNKNOWN:
        return false;
      case TROUBLE_LOST_OR_DAMAGED:
        return true;
      case UNABLE_TO_DELIVER_TODAY:
        return true;
      case ABSENCE:
        return false;
      case ACCEPT_DENIED:
        return true;
      case PAYMENT_NOT_POSSIBLE:
        return false;
      default:
        return false;
    }
  }

  /**
   * 再配達対象の荷物がいくつあるかを返す
   * @returns {{numberOfDelivery: number, numberOfPackages: number}} 再配達対象の配達件数と荷物の個数
   */
  function countTakebackPackages() {
    let numberOfDelivery = 0;
    let numberOfPackages = 0;

    if (takebackPackages.fromDeliveryList.length > 0) {
      for (let i = 0; i < takebackPackages.fromDeliveryList.length; i++) {
        if (
          takebackPackages.fromDeliveryList[i].scanned &&
          !takebackPackages.fromDeliveryList[i].returnTarget
        ) {
          numberOfDelivery++;
          numberOfPackages +=
            takebackPackages.fromDeliveryList[i].numberOfPackages;
        }
      }
    }

    if (takebackPackages.newlyScanned.length > 0) {
      for (let i = 0; i < takebackPackages.newlyScanned.length; i++) {
        if (!takebackPackages.newlyScanned[i].returnTarget) {
          numberOfDelivery++;
          numberOfPackages += takebackPackages.newlyScanned[i].numberOfPackages;
        }
      }
    }
    return {
      numberOfDelivery: numberOfDelivery,
      numberOfPackages: numberOfPackages,
    };
  }

  /**
   * 返品対象の荷物がいくつあるかを返す
   * @param {boolean} [wrongAddressOnly] 住所誤り・住所位置不明のみをカウントする場合にtrueを指定
   * @returns {{numberOfDelivery: number, numberOfPackages: number}} 返品対象の配達件数と荷物の個数
   */
  function countReturnPackages(wrongAddressOnly) {
    let numberOfDelivery = 0;
    let numberOfPackages = 0;

    if (takebackPackages.fromDeliveryList.length > 0) {
      for (let i = 0; i < takebackPackages.fromDeliveryList.length; i++) {
        if (
          takebackPackages.fromDeliveryList[i].scanned &&
          takebackPackages.fromDeliveryList[i].returnTarget &&
          ((wrongAddressOnly &&
            (takebackPackages.fromDeliveryList[i].displayExtraEventType ===
              TROUBLE_NON_ADDRESS ||
              takebackPackages.fromDeliveryList[i].displayExtraEventType ===
                TROUBLE_ADDRESS_UNKNOWN)) ||
            !wrongAddressOnly)
        ) {
          numberOfDelivery++;
          numberOfPackages +=
            takebackPackages.fromDeliveryList[i].numberOfPackages;
        }
      }
    }
    if (takebackPackages.newlyScanned.length > 0) {
      for (let i = 0; i < takebackPackages.newlyScanned.length; i++) {
        if (
          takebackPackages.newlyScanned[i].returnTarget &&
          ((wrongAddressOnly &&
            (takebackPackages.newlyScanned[i].displayExtraEventType ===
              TROUBLE_NON_ADDRESS ||
              takebackPackages.newlyScanned[i].displayExtraEventType ===
                TROUBLE_ADDRESS_UNKNOWN)) ||
            !wrongAddressOnly)
        ) {
          numberOfDelivery++;
          numberOfPackages += takebackPackages.newlyScanned[i].numberOfPackages;
        }
      }
    }
    return {
      numberOfDelivery: numberOfDelivery,
      numberOfPackages: numberOfPackages,
    };
  }

  /**
   * 返品理由を取得
   * @param {number} returnReason
   * @param {number} extraEventType
   * @returns {number}
   */
  function getReturnReasonToRegister(returnReason, extraEventType) {
    if (returnReason) {
      // FIXME: numberに対して if (xxx) を使うのはNG
      return returnReason;
    }
    switch (extraEventType) {
      case TROUBLE_NON_ADDRESS:
        return ReturnReason.ADDRESS_WRONG;
      case TROUBLE_ADDRESS_UNKNOWN:
        return ReturnReason.ADDRESS_UNKNOWN;
      case TROUBLE_LOST_OR_DAMAGED:
        return ReturnReason.SHIPMENT_PROBLEM;
      case ABSENCE:
        return ReturnReason.REDELIVERY_LIMIT_EXCEEDED;
      case ACCEPT_DENIED:
        return ReturnReason.ACCEPT_DENIED;
      case PAYMENT_NOT_POSSIBLE:
        return ReturnReason.REDELIVERY_LIMIT_EXCEEDED;
      default:
        // 実際は発生しない想定（返品ステータスが入ってる場合は返品理由も必ず入ってるはず）
        return ReturnReason.SHIPMENT_PROBLEM;
    }
  }

  function goToBackPage() {
    pageRouter.moveToQrHome(QrHomeTypes.TAKEBACK_PACKAGE_TO_DEPOT);
  }

  /** 戻るボタン押下時の処理 */
  function confirmDiscardAndGoBack() {
    if (
      numberOfTakebackPackages.numberOfDelivery ||
      numberOfReturnPackages.numberOfDelivery
    ) {
      functionAfterDiscard = goToBackPage;
      backConfirmDialog.openDialog();
    } else {
      goToBackPage();
    }
  }

  /**
   * フッタボタン押下時の処理
   * @param {() => void} pageTransition
   */
  function confirmDiscardAndChangePage(pageTransition) {
    if (
      numberOfTakebackPackages.numberOfDelivery ||
      numberOfReturnPackages.numberOfDelivery
    ) {
      functionAfterDiscard = pageTransition;
      backConfirmDialog.openDialog();
    } else {
      pageTransition();
    }
  }

  /**
   * 未スキャンの荷物がある場合にtrue
   * @returns {boolean}
   */
  function hasUnscannedItem() {
    return takebackPackages.fromDeliveryList.some((item) => !item.scanned);
  }

  /**
   * 各荷物の確認事項（複数個口、損壊報告）が全て確認済みかどうかをチェックする
   * ※「損壊」と同じ区分値になる「紛失」のケースは考慮不要（紛失登録した荷物はリストに残っていないため）
   */
  function checkAllCheckpoints() {
    allChecked = true;
    for (let i = 0; i < takebackPackages.fromDeliveryList.length; i++) {
      if (
        !takebackPackages.fromDeliveryList[i].multipleBoxesChecked ||
        (takebackPackages.fromDeliveryList[i].displayExtraEventType ===
          TROUBLE_LOST_OR_DAMAGED &&
          !takebackPackages.fromDeliveryList[i].reportedForAdmin)
      ) {
        allChecked = false;
      }
    }
    for (let i = 0; i < takebackPackages.newlyScanned.length; i++) {
      if (
        !takebackPackages.newlyScanned[i].multipleBoxesChecked ||
        (takebackPackages.newlyScanned[i].displayExtraEventType ===
          TROUBLE_LOST_OR_DAMAGED &&
          !takebackPackages.newlyScanned[i].reportedForAdmin)
      ) {
        allChecked = false;
      }
    }
  }
</script>

<!-- 荷物数カウント-->
<div class="countArea">
  持ち戻り{numberOfTakebackPackages.numberOfPackages}個、返品{numberOfReturnPackages.numberOfPackages}個
</div>

<div class="mainContentsWrapper">
  <Header>
    <svelte:fragment slot="left">
      {#if userContext.loginUser.switchableRoles?.length}
        <RoleIcon />
      {/if}
    </svelte:fragment>
    <svelte:fragment slot="center">
      <Wrapper rich>
        <span tabindex="0" role="button">持ち戻り･返品登録</span>
        <IconButton
          size="button"
          class="material-icons"
          style="vertical-align: sub;">warehouse</IconButton
        >
        {#if centerName}
          <Tooltip xPos="start" unbounded>{centerName}</Tooltip>
        {/if}
      </Wrapper>
    </svelte:fragment>
  </Header>

  <main in:fade>
    <QrCodeScanner
      bind:this={qrCodeScanner}
      onScanSuccessHandler={onScanSuccess}
      onScanErrorHandler={onScanError}
      enableInputForm={true}
    />

    <div id="resultArea">
      {#each takebackPackages.fromDeliveryList as data}
        <div class="resultLine">
          <div class="resultFlex">
            <div
              class:scanedIcon={data.scanned}
              class:notScanedIcon={!data.scanned}
              class:unacquired_icon={data.unacquired}
            >
              <div>
                <span class="material-icons">check_circle</span>
              </div>
            </div>
            <div
              class="resultInfo"
              class:unacquired_result_info={data.unacquired}
            >
              <div class="resultHeadingWrapper">
                <div class="resultHeadingContainer">
                  <div class="resultHeading">
                    {#if data.requestingForReturn || Number.isInteger(data.displayExtraEventType)}
                      <p
                        class="labels {(data.displayExtraEventType ===
                          UNABLE_TO_DELIVER_TODAY ||
                          data.displayExtraEventType === ABSENCE) &&
                        !data.requestingForReturn
                          ? 'green'
                          : 'red'}"
                      >
                        {#if data.requestingForReturn}
                          {$_(
                            `pages.TakebackPackageToDepotWithQrCodeScan.returnLabel`,
                          )}
                        {:else if Number.isInteger(data.displayExtraEventType)}
                          {$_(
                            `pages.TakebackPackageToDepotWithQrCodeScan.extraEventTypeLabel.${data.displayExtraEventType}`,
                          )}
                        {/if}
                      </p>
                    {/if}
                    {#if data.numberOfDeliveryAttempts > 0}
                      <p
                        class="labels {data.numberOfDeliveryAttempts <= 2
                          ? 'blue'
                          : data.numberOfDeliveryAttempts === 3 ||
                              data.numberOfDeliveryAttempts === 4
                            ? 'yellow'
                            : 'red'}"
                      >
                        配達{data.numberOfDeliveryAttempts}回
                      </p>
                    {/if}
                  </div>
                </div>
                {#if data.scanned}
                  <div>
                    <FormField align="end">
                      <span slot="label" style="font-size: 0.8rem;"
                        >{#if data.returnTarget}返品対象{:else}持戻り対象{/if}</span
                      >
                      <Switch
                        on:click={() => {
                          // bindの値反映が遅いため、一旦反転させてから再カウント
                          data.returnTarget = !data.returnTarget;
                          numberOfTakebackPackages = countTakebackPackages();
                          numberOfReturnPackages = countReturnPackages();
                          data.returnTarget = !data.returnTarget;
                        }}
                        bind:checked={data.returnTarget}
                        icons={false}
                        color="secondary"
                        disabled={isCannotSwitchReturnTarget(
                          data.requestingForReturn,
                          data.displayExtraEventType,
                        )}
                      />
                    </FormField>
                  </div>
                {/if}
              </div>
              <p class="tracking">
                <!-- 複数個口の場合は個数を表示 -->
                {formatTrackingNumber(
                  data.trackingNumber,
                )}{#if data.numberOfPackages > 1}<span class="quantity"
                    >（全{data.numberOfPackages}個）</span
                  >{/if}
              </p>
              <p class="address">
                {#if data.correctedReceiverAddress}
                  <span class="highlight">
                    {data.correctedReceiverAddress}
                    <span class="note">※訂正された住所</span>
                  </span>
                {:else}
                  {data.address}
                {/if}
              </p>
              <p class="name">{data.receiverName}</p>
            </div>
          </div>
          {#if data.numberOfDeliveryAttempts >= 3 && !data.returnTarget && !isCannotSwitchReturnTarget(false, data.displayExtraEventType)}
            <!-- IF 配達試行回数が3回以上で返品を促す文言を表示 -->
            <div class="bottomLabelArea">
              <p
                class="labels {data.numberOfDeliveryAttempts >= 5
                  ? 'red'
                  : 'yellow'}"
              >
                <span class="material-icons md-18"> info_outline </span>
                <span class="returnRecommendationNote"
                  >配達回数が多いため返品も検討ください</span
                >
              </p>
            </div>
          {/if}

          {#if data.numberOfPackages > 1 && data.scanned}
            <!-- 複数個口でスキャン済みの場合 -->
            <div class="bottomLabelArea warningText">
              <FormField>
                <Checkbox
                  bind:checked={data.multipleBoxesChecked}
                  on:change={checkAllCheckpoints}
                />
                <span slot="label"
                  >荷物が{data.numberOfPackages}個あることを確認した</span
                >
              </FormField>
            </div>
          {/if}

          {#if data.displayExtraEventType === TROUBLE_LOST_OR_DAMAGED && !data.requestingForReturn && data.scanned}
            <!-- IF 損壊登録ありの場合、配達管理者へ報告したかどうかの確認が必要 -->
            <div class="bottomLabelArea warningText">
              <FormField>
                <Checkbox
                  bind:checked={data.reportedForAdmin}
                  on:change={checkAllCheckpoints}
                />
                <span slot="label">管理者に荷物損壊の報告を行った</span>
              </FormField>
            </div>
          {/if}
        </div>
      {/each}
      {#each takebackPackages.newlyScanned as data}
        <div class="resultLine">
          <div class="resultFlex">
            <div class="scanedIcon" class:unacquired_icon={data.unacquired}>
              <div>
                <span class="material-icons">check_circle</span>
              </div>
            </div>
            <div
              class="resultInfo"
              class:unacquired_result_info={data.unacquired}
            >
              <div class="resultHeadingWrapper">
                <div class="resultHeadingContainer">
                  <div class="resultHeading">
                    {#if data.requestingForReturn || Number.isInteger(data.displayExtraEventType)}
                      <p
                        class="labels {(data.displayExtraEventType ===
                          UNABLE_TO_DELIVER_TODAY ||
                          data.displayExtraEventType === ABSENCE) &&
                        !data.requestingForReturn
                          ? 'green'
                          : 'red'}"
                      >
                        {#if data.requestingForReturn}
                          {$_(
                            `pages.TakebackPackageToDepotWithQrCodeScan.returnLabel`,
                          )}
                        {:else if Number.isInteger(data.displayExtraEventType)}
                          {$_(
                            `pages.TakebackPackageToDepotWithQrCodeScan.extraEventTypeLabel.${data.displayExtraEventType}`,
                          )}
                        {/if}
                      </p>
                    {/if}
                    {#if data.numberOfDeliveryAttempts > 0}
                      <p
                        class="labels {data.numberOfDeliveryAttempts <= 2
                          ? 'blue'
                          : data.numberOfDeliveryAttempts === 3 ||
                              data.numberOfDeliveryAttempts === 4
                            ? 'yellow'
                            : 'red'}"
                      >
                        配達{data.numberOfDeliveryAttempts}回
                      </p>
                    {/if}
                  </div>
                </div>
                <div>
                  <FormField align="end">
                    <span slot="label"
                      >{#if data.returnTarget}返品対象{:else}持戻り対象{/if}</span
                    >
                    <Switch
                      on:click={async () => {
                        // bindの値反映が遅いため、一旦反転させてから再カウント
                        data.returnTarget = !data.returnTarget;
                        numberOfTakebackPackages = countTakebackPackages();
                        numberOfReturnPackages = countReturnPackages();
                        data.returnTarget = !data.returnTarget;
                      }}
                      bind:checked={data.returnTarget}
                      icons={false}
                      color="secondary"
                      disabled={isCannotSwitchReturnTarget(
                        data.requestingForReturn,
                        data.displayExtraEventType,
                      )}
                    />
                  </FormField>
                </div>
              </div>
              <p class="tracking">
                <!-- 複数個口の場合は個数を表示 -->
                {formatTrackingNumber(
                  data.trackingNumber,
                )}{#if data.numberOfPackages > 1}<span class="quantity"
                    >（全{data.numberOfPackages}個）</span
                  >{/if}
              </p>
              {#if !data.unacquired}
                <p class="address">
                  {#if data.correctedAddress}
                    <span class="highlight">
                      {addressUtils.trimPrefecture(data.correctedAddress)}
                      <span class="note">※訂正された住所</span>
                    </span>
                  {:else}
                    {data.address}
                  {/if}
                </p>
                <p class="name">{data.receiverName}</p>
              {/if}

              {#if !data.returnTarget && data.displayExtraEventType === UNABLE_TO_DELIVER_TODAY}
                <ul class="takeBackCaution">
                  <li>
                    配達不可登録が未実施のため、再配達対象として持戻り登録を行います
                  </li>
                </ul>
              {/if}
            </div>
          </div>
          {#if data.numberOfDeliveryAttempts >= 3 && !data.returnTarget && !isCannotSwitchReturnTarget(false, data.displayExtraEventType)}
            <!-- IF 配達試行回数が3回以上で返品を促す文言を表示 -->
            <div class="bottomLabelArea">
              <p
                class="labels {data.numberOfDeliveryAttempts >= 5
                  ? 'red'
                  : 'yellow'}"
              >
                <span class="material-icons md-18"> info_outline </span>
                <span class="returnRecommendationNote"
                  >配達回数が多いため返品も検討ください</span
                >
              </p>
            </div>
          {/if}

          {#if data.numberOfPackages > 1}
            <!-- 複数個口の場合 -->
            <div class="bottomLabelArea warningText">
              <FormField>
                <Checkbox
                  bind:checked={data.multipleBoxesChecked}
                  on:change={checkAllCheckpoints}
                />
                <span slot="label"
                  >荷物が{data.numberOfPackages}個あることを確認した</span
                >
              </FormField>
            </div>
          {/if}

          {#if data.displayExtraEventType === TROUBLE_LOST_OR_DAMAGED && !data.requestingForReturn}}
            <!-- IF 損壊登録ありの場合、配達管理者へ報告したかどうかの確認が必要 -->
            <div class="bottomLabelArea warningTex">
              <div>
                <FormField>
                  <Checkbox
                    bind:checked={data.reportedForAdmin}
                    on:change={checkAllCheckpoints}
                  />
                  <span slot="label">管理者に荷物損壊の報告を行った</span>
                </FormField>
              </div>
            </div>
          {/if}
        </div>
      {/each}

      <div style="height: 70px; background-color: #fff;"></div>
    </div>

    <div class="buttonArea">
      <button class="backBtn" on:click={confirmDiscardAndGoBack}>戻る</button>
      <button
        class:confirmBtn={!btnDisabled && !registering && allChecked}
        class:disabledBtn={btnDisabled || registering || !allChecked}
        disabled={btnDisabled ||
          registering ||
          !allChecked ||
          numberOfTakebackPackages.numberOfDelivery +
            numberOfReturnPackages.numberOfDelivery ===
            0}
        on:click={() => {
          if (!checkReportedForAdmin()) {
            toast.error($_("errors.needReportForAdmin"));
          } else {
            confirmDialog.openDialog();
          }
        }}
      >
        {#if !registering}確定{:else}登録中{/if}
      </button>
    </div>
  </main>

  <Footer {confirmDiscardAndChangePage} />
</div>
<div class="subContentsWrapper">
  <!-- 戻るダイアログ -->
  <ConfirmDialog
    bind:this={backConfirmDialog}
    mandatory={true}
    type={ConfirmDialogTypes.OK_CLOSE}
    onDialogClosedHandler={(event) => {
      if (event.detail.action === "ok") {
        functionAfterDiscard();
      }
    }}
  >
    <svelte:fragment slot="title">確認</svelte:fragment>
    <svelte:fragment slot="content"
      >読み込んだ荷物情報は破棄されます。<br />よろしいですか？</svelte:fragment
    >
  </ConfirmDialog>

  <!-- 確認ダイアログ -->
  <ConfirmDialog
    bind:this={confirmDialog}
    mandatory={true}
    type={ConfirmDialogTypes.OK_CLOSE}
    onDialogClosedHandler={(event) => {
      if (event.detail.action === "ok") {
        updateStatuses();
      }
    }}
  >
    <svelte:fragment slot="title">登録確認</svelte:fragment>
    <svelte:fragment slot="content"
      >以下件数の荷物を登録します。<br />
      それぞれのカゴ車に入れる荷物の個数が合っているか確認してください。
      <ul>
        <li>
          <div>
            <span>持ち戻り（再配達）</span>
            <span>
              {countTakebackPackages()
                .numberOfDelivery}件（{countTakebackPackages()
                .numberOfPackages}個）</span
            >
          </div>
        </li>
        <li>
          <div>
            <span>返品</span>
            <span>
              {countReturnPackages().numberOfDelivery}件（{countReturnPackages()
                .numberOfPackages}個）</span
            >
          </div>
        </li>
        {#if countReturnPackages(true).numberOfDelivery > 0}
          <div class="wrondAddress">
            <span>※内、住所誤り</span>
            <span>
              {countReturnPackages(true)
                .numberOfDelivery}件（{countReturnPackages()
                .numberOfPackages}個）</span
            >
          </div>
        {/if}
      </ul>
      {#if hasUnscannedItem()}
        <div class="unscannedItemNotice">
          <span
            >持ち戻り・返品登録が必要な荷物が残っています。
            スキャン漏れ、あるいは荷物の紛失でないことをご確認ください。
          </span>
        </div>
      {/if}
    </svelte:fragment>
  </ConfirmDialog>
</div>

<style lang="scss">
  .countArea {
    position: absolute;
    top: 44px;
    left: 4px;
    color: #fff;
    z-index: 10;
  }

  main {
    display: flex;
    flex-direction: column;
  }

  #resultArea {
    flex: 1;
    overflow-y: auto;
    background-color: #fff;
  }

  .resultLine {
    padding: 0 10px 10px;
    border-bottom: 1px solid #ccc;
  }

  .resultFlex {
    display: flex;
    gap: 10px;

    .material-icons {
      font-size: 30px;
    }

    .scanedIcon {
      margin: auto 0;
      color: #02b555;
    }

    .notScanedIcon {
      margin: auto 0;
      color: #dddddd;
    }

    .unacquired_icon {
      margin: auto 0;
    }

    .resultInfo {
      display: column;
      padding: 6px 0;
      line-height: 1.32;
      width: 100%;
    }

    .resultHeadingWrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .resultHeadingContainer {
      margin-top: 10px;
    }

    .resultHeading {
      display: flex;
      justify-content: start;
      gap: 4px;
    }

    .tracking {
      .quantity {
        color: #c62800;
      }
    }

    .takeBackCaution {
      color: #c80000;
      font-size: 13px;
      margin-top: 4px;

      li {
        list-style: none;
        margin-left: 1.2em;
        text-indent: -1.2em;
      }

      li::before {
        content: "※";
        margin-right: 0.2em;
      }
    }

    .unacquired_result_info {
      margin-top: 10px;
    }

    .address {
      .highlight {
        padding: 3px;
        border-radius: 4px;
        background-color: #facfcf;
      }

      .note {
        font-size: 11.4px;
        color: #c80000;
      }
    }
  }

  .labels {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 2px;
    font-size: 13px;
    padding: 2px 3px;
    height: fit-content;
  }

  .red {
    color: #c80000;
    background-color: #ffe7e7;
  }

  .blue {
    color: #0000cd;
    background-color: #e6f6ff;
  }

  .yellow {
    color: #673a1a;
    background-color: #ffffeb;
  }

  .green {
    color: #018786;
    background-color: #e7f4f4;
  }

  .bottomLabelArea {
    margin-top: 4px;

    .returnRecommendationNote {
      margin-left: 4px;
    }
  }

  .material-icons.md-18 {
    font-size: 18px;
  }

  .warningText {
    font-size: 14px;
    margin-top: 10px;
    background-color: #ffe7e7;
    color: #c80000;
    border-radius: 4px;
    padding: 3px 4px;

    :global(.mdc-form-field) {
      color: #c80000;
    }
  }

  .bottomLabelArea {
    margin-top: 4px;

    .returnRecommendationNote {
      margin-left: 4px;
    }
  }

  .material-icons.md-18 {
    font-size: 18px;
  }

  .buttonArea {
    button {
      width: 60px;
      height: 60px;
      border: none;
      border-radius: 50%;
      font-weight: bold;
      color: #fff;
    }

    .backBtn {
      position: fixed;
      font-size: 16px;
      bottom: calc(70px + var(--app-inset-bottom-padding));
      left: 25px;
      padding: 0;
      margin: 0;
      background-color: #018786;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
    }

    .confirmBtn {
      position: fixed;
      font-size: 16px;
      bottom: calc(70px + var(--app-inset-bottom-padding));
      right: 25px;
      padding: 0;
      margin: 0;
      background-color: #018786;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
    }

    .disabledBtn {
      position: fixed;
      font-size: 16px;
      bottom: calc(70px + var(--app-inset-bottom-padding));
      right: 25px;
      padding: 0;
      margin: 0;
      background-color: #ccc;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
    }
  }

  .subContentsWrapper {
    position: relative;
    z-index: 20;

    ul {
      margin: 5px auto;
    }

    li {
      list-style-type: square;
      margin-top: 8px;

      div {
        display: flex;
        justify-content: space-between;
        gap: 8px;
      }
    }

    li::marker {
      color: #018786;
    }

    .wrondAddress {
      display: flex;
      justify-content: space-between;
      gap: 8px;
      margin-top: 2px;
    }

    .unscannedItemNotice {
      margin-top: 5px;
      padding: 8px 12px;
      background-color: #ffe8e8;
      border-radius: 4px;
      font-size: 14px;
      line-height: 18px;
      font-weight: bold;
    }
  }
</style>
