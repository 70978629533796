import { format as formatDate } from "date-fns";
import sleep from "sleep-promise";
import { get } from "svelte/store";

import backendApi from "~/libs/backendApi";
import geolocator from "~/libs/geolocator";
import logger from "~/libs/logger";
import { currentPositionStore } from "~/libs/stores";

/**
 * @type {{
 *   undeliveredList: Array<string>,
 *   deliveredList: Array<string>,
 *   undeliverableList: Array<string>,
 *   updateDeliveryListAt: string,
 *   inTransitDeliveryList: Array<import("~/libs/backendApi").inTransitDelivery>,
 *   updateInTransitDeliveryListAt: string,
 * }}
 * 前回同期した稼働実績の情報
 */
let lastSyncedOperationState;

/** @type {string} 現在時刻 */
let now = "";

/** @type {GeolocationPosition} 現在位置 */
let currentPosition;

/**
 * ドライバー稼働状況(配達実績/現在地)同期APIを実施
 * @param {import("~/libs/commonTypes").UserContext} userContext
 */
export async function syncOperationState(userContext) {
  // 現在位置の取得
  geolocator.requestCurrentPosition(false);

  // 現在位置の取得に時間がかかるため、少しsleepを入れる
  await sleep(1000);

  // storeから現在位置を取得
  currentPosition = get(currentPositionStore);

  lastSyncedOperationState = userContext.lastSyncedOperationState ?? {
    undeliveredList: [],
    deliveredList: [],
    undeliverableList: [],
    updateDeliveryListAt: "",
    inTransitDeliveryList: [],
    updateInTransitDeliveryListAt: "",
  };

  now = formatDate(new Date(), "yyyy-MM-dd HH:mm:ss");

  if (userContext.deliveryList) {
    // ローカルストレージに配達リストが存在する場合、宅配ドライバーとしての稼働実績をBEに同期する
    await syncOperationStateOfDriver(userContext);
  }
  if (userContext.inTransitDeliveryList) {
    // ローカルストレージに幹線輸送リストが存在する場合、幹線輸送ドライバーとしての稼働実績をBEに同期する
    await syncOperationStateOfCoreDelivery(userContext);
  }

  userContext.lastSyncedOperationState = lastSyncedOperationState;
  userContext.store();
}

/**
 * 宅配ドライバーとしての稼働実績をBEに同期する
 * @param {import("~/libs/commonTypes").UserContext} userContext
 */
async function syncOperationStateOfDriver(userContext) {
  // ローカルストレージの配達リストからそれぞれのステータスの送り状番号のリストを作成
  let undeliveredList =
    userContext.deliveryList
      .filter((e) => e.statusText === "未")
      ?.map((e) => e.trackingNumber) ?? [];
  let deliveredList =
    userContext.deliveryList
      .filter((e) => e.statusText === "済")
      ?.map((e) => e.trackingNumber) ?? [];
  let undeliverableList =
    userContext.deliveryList
      .filter((e) => e.statusText === "不")
      ?.map((e) => e.trackingNumber) ?? [];

  // 配達リストの更新日時を設定
  let updateDeliveryListAt;
  if (
    lastSyncedOperationState?.updateDeliveryListAt === "" ||
    JSON.stringify(undeliveredList) !==
      JSON.stringify(lastSyncedOperationState?.undeliveredList) ||
    JSON.stringify(deliveredList) !==
      JSON.stringify(lastSyncedOperationState?.deliveredList) ||
    JSON.stringify(undeliverableList) !==
      JSON.stringify(lastSyncedOperationState?.undeliverableList)
  ) {
    // 前回同期時の配達リストの更新日時が空（＝宅配ドライバーとしての同期が初）、または配達リストが前回同期時と異なる場合
    updateDeliveryListAt = now;
  } else {
    // 変更がない場合は前回の更新日時を設定
    updateDeliveryListAt = lastSyncedOperationState.updateDeliveryListAt;
  }

  /** @type {import("~/libs/backendApi").DeliveryRecordOfDriver}*/
  let deliveryRecordOfDriver = {
    locationSourceIdList: userContext.locationSourceIdList,
    undeliveredList: undeliveredList,
    deliveredList: deliveredList,
    undeliverableList: undeliverableList,
    updateLocationAt: now,
    updateDeliveryListAt: updateDeliveryListAt,
  };

  // 今回同期するデータを保存
  lastSyncedOperationState.undeliveredList = undeliveredList;
  lastSyncedOperationState.deliveredList = deliveredList;
  lastSyncedOperationState.undeliverableList = undeliverableList;
  lastSyncedOperationState.updateDeliveryListAt = updateDeliveryListAt;

  /** @type {import("~/libs/backendApi").OperationState} */
  const requestBody = {
    driverType: 1,
    results: deliveryRecordOfDriver,
    location: {
      latitude: currentPosition?.coords?.latitude,
      longitude: currentPosition?.coords?.longitude,
    },
  };

  await execSyncOperationStateAPI(requestBody, userContext);
}

/**
 * 幹線輸送ドライバーとしての稼働実績をBEに同期する
 * @param {import("~/libs/commonTypes").UserContext} userContext
 */
async function syncOperationStateOfCoreDelivery(userContext) {
  // 現在の輸送中荷物リストから同期用の輸送中荷物リストを作成
  /** @type {Array<import("~/libs/backendApi").inTransitDelivery>} 輸送中の荷物のリスト*/
  let inTransitDeliveryList = [];
  for (const inTransitDeliveryInfoInUserContext of userContext.inTransitDeliveryList) {
    let transportSourceId =
      inTransitDeliveryInfoInUserContext.transportSourceId;
    /** @type {Array<{transportDestivationId: number, trackingNumberList: Array<string>}>} */
    let deliveryInfoList = [];

    for (const deliveryInfoInUserContext of inTransitDeliveryInfoInUserContext.deliveryInfoList) {
      let transportDestivationId =
        deliveryInfoInUserContext.transportDestinationId;
      let trackingNumberList = [];

      for (const basketCar of deliveryInfoInUserContext.basketCarList) {
        for (const trackingAndQuantity of basketCar.v) {
          trackingNumberList.push(trackingAndQuantity.trackingNumber);
        }
      }

      /** @type {{transportDestivationId: number, trackingNumberList: Array<string>}} */
      let deliveryInfo = {
        transportDestivationId: transportDestivationId,
        trackingNumberList: trackingNumberList,
      };

      deliveryInfoList.push(deliveryInfo);
    }

    /** @type {import("~/libs/backendApi").inTransitDelivery} */
    let inTransitDelivery = {
      transportSourceId: transportSourceId,
      deliveryInfoList: deliveryInfoList,
    };

    inTransitDeliveryList.push(inTransitDelivery);
  }

  // 輸送中荷物リストの更新日時を設定
  let updateInTransitDeliveryListAt;
  if (
    lastSyncedOperationState?.updateInTransitDeliveryListAt === "" ||
    JSON.stringify(inTransitDeliveryList) !==
      JSON.stringify(lastSyncedOperationState?.inTransitDeliveryList)
  ) {
    // 前回同期時の輸送中荷物リストの更新日時が空（＝幹線輸送ドライバーとしての同期が初）、または輸送中荷物リストが前回同期時と異なる場合
    updateInTransitDeliveryListAt = now;
  } else {
    // 変更がない場合は前回の更新日時を設定
    updateInTransitDeliveryListAt =
      lastSyncedOperationState.updateInTransitDeliveryListAt;
  }

  /** @type {import("~/libs/backendApi").DeliveryRecordOfCoreDelivery}*/
  let deliveryRecordOfCoreDelivery = {
    inTransitDeliveryList: inTransitDeliveryList,
    updateLocationAt: now,
    updateInTransitDeliveryListAt: updateInTransitDeliveryListAt,
  };

  // 今回同期するデータを保存
  lastSyncedOperationState.inTransitDeliveryList = inTransitDeliveryList;
  lastSyncedOperationState.updateInTransitDeliveryListAt =
    updateInTransitDeliveryListAt;

  /** @type {import("~/libs/backendApi").OperationState} */
  const requestBody = {
    driverType: 0,
    results: deliveryRecordOfCoreDelivery,
    location: {
      latitude: currentPosition?.coords?.latitude,
      longitude: currentPosition?.coords?.longitude,
    },
  };

  await execSyncOperationStateAPI(requestBody, userContext);
}

/**
 * ドライバー稼働状況(配達実績/現在地)同期APIを実施
 * @param {import("~/libs/backendApi").OperationState} requestBody
 * @param {import("~/libs/commonTypes").UserContext} userContext
 */
async function execSyncOperationStateAPI(requestBody, userContext) {
  try {
    await backendApi.syncOperationState(requestBody);
  } catch (error) {
    // 稼働実績が同期できなくても業務は継続できるため、エラーログだけ出して続行
    logger.error(
      "[syncOperationState] 稼働実績の同期でエラーが発生しました",
      {
        username: userContext.loginUser?.username,
      },
      error,
    );
  }
}
